import React from "react";
import { Text,TouchableOpacity } from "react-native";

function Button(props) {
  return (

      <TouchableOpacity style={props.buttonStyle} onPress={props.onPress}>
        <Text style={props.buttonStyleTxt}>{props.text}</Text>
      </TouchableOpacity>
  );
}

export default Button;
