import firebase from 'firebase/app'

const firebaseConfig = {
  apiKey: "AIzaSyCspxJZeO8y6q9GCLjZXKeMKNsblzwudgc",
  authDomain: "pop-it-5f798.firebaseapp.com",
  databaseURL: "https://pop-it-5f798-default-rtdb.firebaseio.com",
  projectId: "pop-it-5f798",
  storageBucket: "pop-it-5f798.appspot.com",
  messagingSenderId: "696791342810",
  appId: "1:696791342810:web:232275703c9a09091ecfe3",
  measurementId: "G-MMWJCFV15D"
};
  
  // Initialize Firebase
firebase.initializeApp(firebaseConfig);