import React, { useState, useRef } from "react";
import TableComp from "./Table";
import Papa from "papaparse";
import { ArrowLeftCircleIcon, File, Loader2 } from "lucide-react";
import "../components/Firebase";
import firebase from "firebase/app";
import "firebase/database";
import Membership from "./Membership";
import ReactLoading from "react-loading";
const ImportManager = ({ setactive }) => {
  const [TableData, setTableData] = useState([]);
  const [qna, setQna] = useState(true);

  const [isDisabled, setIsDisabled] = useState(true);
  const fileInputRef = useRef(null); // Create a reference for the file input

  const requiredFields = [
    "email",
    "password",
    "name",
    "job",
    "company",
    "number",
  ];

  const [filenmae, setfilenmae] = useState(null);
  const [editManager, seteditManager] = useState(false);
  const [editindex, seteditindex] = useState(null);
  const [dates, setDates] = useState({ start: null, end: null });
  const [membershipType, setMembershipType] = useState(null);

  const [modified, setmodified] = useState(false);
  const [formData, setFormData] = useState({});
  const [success, setSuccess] = useState([]);


  const handleFileChange = (event) => {


    const file = event.target.files[0];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (file && (file.type === "text/csv" || fileExtension === "xlsx")) {
      setfilenmae(file.name);

      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target.result;

        Papa.parse(text, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {

            const hasEmptyRequiredFields = results.data.some((row) => {
              return requiredFields.some(
                (field) => !row[field] || row[field].trim() === ""
              );
            });

            if (hasEmptyRequiredFields) {
              setIsDisabled(true); 
              setTimeout(() => {
                alert(`
                                "Email For Login",
                                "Password",
                                "Full Name",
                                "Job Title",
                                "Company Name",
                                "Number",
                                These fields cannot be empty
                            `);
              }, 1000);
            } else {
              setIsDisabled(false); 

              const updatedData = results.data.map((row) => {
                if (membershipType === "pro") {
                  const startDate = dates.start ? new Date(dates.start) : null;
                  const endDate = dates.end ? new Date(dates.end) : null;

                  const incrementByOneDay = (date) => {
                    if (date) {
                      const newDate = new Date(date);
                      newDate.setDate(newDate.getDate() + 1); 
                      return newDate.toISOString().slice(0, 10);
                    }
                    return null;
                  };

                  return {
                    ...row,
                    membership: "pro",
                    date_start: incrementByOneDay(startDate),
                    date_end: incrementByOneDay(endDate),
                  };
                }
                return row;
              });

              setTableData(updatedData); 
            }

            if (fileInputRef.current) {
              fileInputRef.current.value = null;
            }
          },
        });
      };

      reader.readAsText(file);
    } else {
      alert("Please upload a valid CSV or Excel file.");
      setTableData([]);
      setIsDisabled(true);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setmodified(true);
  };

  function formatDate() {
    const options = { weekday: "long", month: "numeric", day: "numeric" };
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", options);
    return formattedDate;
  }

  function generateToken(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let token = "";
    for (let i = 0; i < length; i++) {
      token += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return token;
  }

  const [loading, setLoading] = useState(false);

  // Define a function to map the array into the desired structure
  const mapToObjects = (array, name, image) => {
    if (!array || array.length === 0) return [];

    return array.map((value) => ({
      name: name,
      title: name,
      image: image,
      highlight: false,
      check: true,
      description: "",
      value: value.trim(),
    }));
  };

  const handleBulkRegister = async (e) => {
    e.preventDefault();
    setLoading(true);

    const results = [];

    for (const {
        email,
        password,
        address,
        name,
        job,
        company,
        Pre_link,
        membership,
        date_end,
        date_start,
        number,
        bio,
        call,
        emails,
        website,
        whatsapp,
        adresses,
        linkedin,
        instagram,
        facebook,
        tiktok,
        reviews,
        applink,
        trustpilot,
        device,
        Logo,
        Banner,
        Profile
      } of TableData) {

      // Delay 2 seconds before each account creation
      await new Promise(resolve => setTimeout(resolve, 2000));

      try {
        const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
        const uid = userCredential.user.uid;
        const index = generateToken(10);
        const formattedDate = formatDate();
        const isDarkTheme = false; // Replace with your theme logic if needed

        // Convert comma-separated strings into arrays and then objects
        const call1 = call ? call.split(",") : [];
        const emails1 = emails ? emails.split(",") : [];
        const website1 = website ? website.split(",") : [];
        const whatsapp1 = whatsapp ? whatsapp.split(",") : [];
        const addresses1 = adresses ? adresses.split(",") : [];
        const linkedin1 = linkedin ? linkedin.split(",") : [];
        const instagram1 = instagram ? instagram.split(",") : [];
        const facebook1 = facebook ? facebook.split(",") : [];
        const tiktok1 = tiktok ? tiktok.split(",") : [];
        const reviews1 = reviews ? reviews.split(",") : [];
        const applink1 = applink ? applink.split(",") : [];
        const trustpilot1 = trustpilot ? trustpilot.split(",") : [];
        const token = Pre_link ? Pre_link.split('/').pop() : null;

        let count = 1;
        const callObjects = mapToObjects(call1, "Call", "applecall.png", count++);
        const emailObjects = mapToObjects(emails1, "Email", "applemail.png", count++);
        const websiteObjects = mapToObjects(website1, "Website", "safari.png", count++);
        const whatsappObjects = mapToObjects(whatsapp1, "WhatsApp", "whatsapp.png", count++);
        const addressObjects = mapToObjects(addresses1, "Address", "address.png", count++);
        const linkedinObjects = mapToObjects(linkedin1, "LinkedIn", "LinkedIn_logo_initials.webp", count++);
        const instagramObjects = mapToObjects(instagram1, "Instagram", "instagram_nobg.png", count++);
        const facebookObjects = mapToObjects(facebook1, "Facebook", "facebook_logo.png", count++);
        const tiktokObjects = mapToObjects(tiktok1, "TikTok", "tiktok.png", count++);
        const reviewsObjects = mapToObjects(reviews1, "Reviews", "google.png", count++);
        const applinkObjects = mapToObjects(applink1, "App Store", "appstore.png", count++);
        const trustpilotObjects = mapToObjects(trustpilot1, "Trustpilot", "trustpilot.png", count++);

        await firebase.database().ref("/users/" + uid).update({
          membership: membership
            ? {
                status: "pro",
                date_end: new Date(date_end),
                date_start: new Date(date_start),
              }
            : "normal",
          email,
          scan_complete: false,
          share_complete: false,
          preview: false,
          tap_score: 0,
          share_score: 0,
          task_completed: 0,
          name,
          cards: [
            {
              card: "Set Card Title",
              index,
              summary: `${name || ""} - ${job || ""} @ ${company || ""}`,
              date: formattedDate,
              job: job || "",
              address: address || "",
              company: company || "",
              name,
              number: number || "",
              image: Profile || "",
              color: isDarkTheme ? "#292929" : "white",
              logo: Logo || "",
              cover: Banner || "",
              bio: bio || "",
              favorite: "no",
              qr_link: `https://popitapp.nl/connect/${index}/hash/${uid}`,
              alignment: "center",
              single: false,
              lead: false,
              call: callObjects,
              emails: emailObjects,
              website: websiteObjects,
              whatsapp: whatsappObjects,
              addresses: addressObjects,
              linkedin: linkedinObjects,
              instagram: instagramObjects,
              facebook: facebookObjects,
              tiktok: tiktokObjects,
              reviews: reviewsObjects,
              applink: applinkObjects,
              trustpilot: trustpilotObjects,
            },
          ],
          connection: [
            { uid: "LPuWSpkKtwaj6UDRfIgLile8kS43", index: "7xZv18wpLv", favorite: "no" },
            { uid: "OlOr30ZkgrbsqBJdaa4iDI8I5vA3", index: "H5AXFMyyk6", favorite: "no" },
            { uid: "RuhwH6mfefOuchRe7DPCalPGyxD3", index: "UC0mmTYJOS", favorite: "no" },
          ],
          task_completed: 0,
          main_card: [
            {
              index,
              summary: `${name || ""} - ${job || ""} @ ${company || ""}`,
              favorite: "no",
              date: formattedDate,
              job: job || "",
              company: company || "",
              name,
              number: number || "",
              image: Profile || "",
              address: address || "",
              color: isDarkTheme ? "#292929" : "white",
              logo: Logo || "",
              cover: Banner || "",
              bio: bio || "",
              card: "Set Card Title",
              alignment: "center",
              single: false,
              lead: false,
              qr_link: `https://popitapp.nl/connect/${index}/hash/${uid}`,
              call: callObjects,
              emails: emailObjects,
              website: websiteObjects,
              whatsapp: whatsappObjects,
              addresses: addressObjects,
              linkedin: linkedinObjects,
              instagram: instagramObjects,
              facebook: facebookObjects,
              tiktok: tiktokObjects,
              reviews: reviewsObjects,
              applink: applinkObjects,
              trustpilot: trustpilotObjects,
            },
          ],
        });

        if (token && device) {
          ActivateDevice(token, device.charAt(0).toUpperCase() + device.slice(1), index, uid);
        }

        setSuccess((prev) => [...prev, { email, success: true, uid }]);
        results.push({ email, success: true, uid });

      } catch (error) {
        console.error(`Error creating user with email ${email}:`, error);
        setSuccess((prev) => [...prev, { email, success: false, error: error.message }]);
        results.push({ email, success: false, error: error.message });
      }
    }

    setLoading(false);

    if (results.some((result) => result.success === false)) {
      alert("An error occurred during user creation.");
    } else {
      alert("Users created successfully.");
      
      // setactive(null);
    }
  };


  const ActivateDevice = (token, device, index, uid) => {
    firebase
      .database()
      .ref("/users/card_details/" + token)
      .update({
        link: `https://popitapp.nl/connect/${index}-${device.toLowerCase()}/hash/${uid}`,
      })
      .then(() => {})
      .catch((error) => console.log("Error", error));

    firebase
      .database()
      .ref(`/users/${uid}/cards`)
      .once("value")
      .then(function (snapshot) {
        var userData = snapshot.val();

        if (userData) {
          const date = new Date();
          const day = date.getDate();
          const month = date.getMonth() + 1; // Months in JavaScript are zero-indexed, so we need to add 1 to get the correct month.
          const year = date.getFullYear();
          const dateString = `${month}/${day}/${year}`;

          var activated = [];
          var push = ["none"];
          activated.push(device);

          var date1 = [];
          date1.push(dateString);
          var token1 = [];
          token1.push(token);
          var data1 = userData.map((item) => {
            if (item.index === index) {
              if (item["token"]) item["token"].push(token);
              else item["token"] = token1;

              if (item["date_activate"]) item["date_activate"].push(dateString);
              else item["date_activate"] = date1;

              if (item["activated"]) item["activated"].push(device);
              else item["activated"] = activated;

              if(item['push']) item['push'].push('none')
              else item['push'] = push
            }
            return item;
          });

          firebase.database().ref(`/users/${uid}`).update({ cards: data1 });
          firebase.database().ref(`/users/${uid}`).update({ main_card: data1 });
        }
      });
  };

  return (
    <>
      {!editManager ? (
        <div className="lg:w-[95%] backdrop_image backdrop-blur-2xl h-fit mx-auto my-auto bg-gray-50 p-5 lg:p-10 flex flex-col gap-5 rounded-sm lg:rounded-2xl">
          <div className="flex items-center ">
            <ArrowLeftCircleIcon
              className="text-gray-700 cursor-pointer"
              onClick={() => {
                setactive(null);
              }}
            />
            <h1
              style={{ backgroundColor: "rgba(255, 255, 255, 0.267)" }}
              className="font-semibold text-2xl text-center  backdrop-blur-xl w-fit mx-auto text-black p-5 rounded-md "
            >
              Import Manager
            </h1>
          </div>
          {qna ? (
            <Membership
              setQna={setQna}
              dates={dates}
              setDates={setDates}
              membershipType={membershipType}
              setMembershipType={setMembershipType}
            />
          ) : (
            <div className="csvSelector">
              <button
                onClick={() => {
                  fileInputRef.current.click();
                }}
                className="bg-gray-50 border text-gray-600 px-10 w-fit rounded-xl p-5 flex gap-2 items-center "
              >
                <p>{filenmae} </p>
                {!filenmae && (
                  <>
                    <File />
                    <p className="font-medium text-lg">Select a Csv or Excel File </p>
                  </>
                )}
              </button>
              <input
                className="hidden"
                type="file"
                ref={fileInputRef} // Attach the ref to the input
                onChange={handleFileChange}
                accept=".csv, .xlsx"
              />
            </div>
          )}
          <div className="table max-h-[70vh] overflow-auto">
            <TableComp
              data={TableData}
              seteditManager={seteditManager}
              setFormData={setFormData}
              seteditindex={seteditindex}
            />
          </div>

          {success? (
            <div className="h-[100px] overflow-y-auto">
            {success.map((itm, index) => (
            <p key={index}>
              {itm.success ? (
                <>✅ Successfully created user: {itm.email}</>
              ) : (
                <>❌ Failed to create user: {itm.email} - Error: {itm.error}</>
              )}
            </p>
          ))}
          </div>
          ): null}

          
          
          <button
            className={`w-fit px-4 py-2 rounded-lg text-xl border-2 ${
              isDisabled
                ? "hidden"
                : loading
                ? "bg-gray-500 border-gray-500 text-white "
                : "bg-blue-500 border-blue-500 text-white hover:bg-transparent hover:text-black"
            }`}
            onClick={handleBulkRegister}
            disabled={isDisabled || loading}
          >
            {loading ? <Loader2 className="animate-spin" /> : "Create"}
          </button>
        </div>
      ) : (
        <div className="lg:w-[95%] backdrop_image backdrop-blur-2xl h-fit mx-auto my-auto bg-gray-50 p-5 lg:p-10 flex flex-col gap-5 rounded-sm lg:rounded-2xl">
          <div className="flex items-center ">
            <ArrowLeftCircleIcon
              className="text-gray-700 cursor-pointer"
              onClick={() => {
                seteditManager(false);
              }}
            />
            <h1
              style={{ backgroundColor: "rgba(255, 255, 255, 0.267)" }}
              className="font-semibold text-2xl text-center  backdrop-blur-xl w-fit mx-auto text-black p-5 rounded-md "
            >
              Edit Manager
            </h1>
          </div>
          <div className="fields grid grid-cols-4 gap-2 max-h-[70vh] overflow-auto">
            {Object.keys(formData).map((key) => (
              <div key={key} className="field ">
                <label htmlFor={key} className="block  text-gray-700">
                  {key}
                </label>
                <input
                  type="text"
                  id={key}
                  name={key}
                  value={formData[key] || ""}
                  onChange={handleChange}
                  className="mt-1 p-3 block w-full  outline-none border-none rounded-md shadow-sm text-sm"
                />
              </div>
            ))}
          </div>
          <button
            onClick={() => {
              setTableData((prev) => {
                const newData = prev.map((data, i) => {
                  if (i === editindex) {
                    return formData;
                  } else {
                    return data;
                  }
                });

                return newData;
              });
              seteditManager(false);
            }}
            className={`bg-blue-500 active:bg-blue-500 w-fit px-4 py-2 rounded-lg text-xl hover:bg-transparent border-blue-500 border-2 hover:text-black text-white ${
              !modified ? "hidden" : ""
            }`}
            disabled={!modified}
          >
            Update
          </button>
        </div>
      )}
    </>
  );
};

export default ImportManager;