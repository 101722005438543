import React from 'react';
import {useParams} from "react-router-dom"

function Success() {  
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <div>
          <img
            src={require("../images/pop_it_Black_wb.png")}
            style={{ width: 150, height: 80 }}
          ></img>
        </div>
        <p style={{ fontFamily: "Roboto" }}>
          Payment Successful!
        </p>
      
      </div>
    );
}

export default Success;