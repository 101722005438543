import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import VCard from "vcard-creator";
import "firebase/database";
import firebase from "firebase";
import "../components/Firebase";
import ReactLoading from "react-loading";
import axios from "axios";


function Test() {
  const queryParams = useParams();
  const [email, setemail] = useState('')

  var vCardData = new VCard();

  var contactlinks = [
    "numbers",
    "emails",
    "addresses",
    // "ContactCard",
    "whatsapp",
    "facetime",
    "call",
    "wechat",
    "instagram",
    "linkedin",
    "facebook",
    "youtube",
    "tiktok",
    "twitter",
    "snapchat",
    "clubhouse",
    "twitch",
    "pinterest",
    "website",
    "calendly",
    "reviews",
    "etsy",
    "appLink",
    "chiliPiper",
    "booksy",
    "square",
    "yelp",
    "embedded",
    "zillow",
    "file",
    "cash",
    "venmo",
    "zelle",
    "paypal",
    "customLink",
    "linktree",
    "discord",
    "telegram",
    "opensea",
    "poshmark",
    "podcasts",
    "hoobe",
    "applemusic",
    "spotify",
    "soundcloud",
    "header",
    "trustpilot"
    // "expandable",
  ];

  const saveContact = (queryParams) => {

    firebase
        .database()
        .ref(`users/${queryParams.uid}/contact`)
        .once("value")
        .then(async(snapshot) => {
          const cards = snapshot.val();
          const contact = cards.filter((data) => {
            return data.index === queryParams.index;
          });

          const name1 = contact[0].name.includes(" ")
            ? contact[0].name.split(" ")
            : contact[0].name;
          const first_name = typeof name1 === "string" ? contact[0].name : name1[0];
          const last_name = typeof name1 === "string" ? "" : name1[name1.length - 1];
          if (queryParams.file === "contact") {
            vCardData
              .addName(last_name, first_name || "No Name")
              .addCompany(contact[0].company || "No Company")
              .addJobtitle(contact[0].jobTitle || "No Job")
              .addRole(contact[0].jobTitle || "No Job")
              .addEmail(contact[0].email || "Null")
              .addNote(contact[0].note || "No Note")

              .addSocial(
                `https://www.instagram.com/${contact[0].instagram}`,
                "Instagram",
                contact[0].instagram
              )
              .addPhoneNumber(contact[0].number, "PREF;WORK")
              .addURL(contact[0].website);

            // Create a Blob with the vCard data

            const element = document.createElement("a");

            element.setAttribute(
              "href",
              "data:text/vcard;charset=utf-8," +
                encodeURIComponent(vCardData.toString())
            );
            if (contact[0].name) {
                element.setAttribute("download", `${contact[0].name}.vcf`);
              } else {
                element.setAttribute("download", `Contact.vcf`);
              }
            element.style.display = "none";
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
          } else if (queryParams.file === "contact_csv") {
            // Create the CSV content from the dictionary data

            

            const headers = [
              "Name",
              "Company",
              "Job",
              "Email",
              "Number",
              "Website",
              "Note",
              "Locaton",
            ];
            const csvContent = [headers.join(",")]; // Creating the header row

            contact.forEach((item) => {
              const row = [
                item.name,
                item.company,
                item.jobTitle,
                item.email,
                item.number,
                item.website,
                item.note,
                item.location,
              ];
              csvContent.push(row.join(","));
            });

            const prompt = window.confirm("Do you want to download Csv?");

            if (prompt) {
              // Create a Blob with the CSV data
              const blob = new Blob([csvContent.join("\n")], {
                type: "text/csv",
              });

              // Create a URL for the Blob
              const url = URL.createObjectURL(blob);

              // Create a temporary anchor element to trigger the download
              const a = document.createElement("a");
              a.href = url;
              a.download = "data.csv";
              a.click();

              // Clean up resources
              URL.revokeObjectURL(url);
            }
          }
        });
  }

  useEffect(() => {

    if (queryParams.file.includes("card")) {
      if(queryParams.file === "card") {
      var vCardData = new VCard();
      firebase
        .database()
        .ref(`users/${queryParams.uid}`)
        .once("value")
        .then((snapshot) => {
          const emaildata = snapshot.val();

          vCardData.addEmail(
            emaildata.email || "Email does not exist",
            "Email"
          );
        });

      firebase
        .database()
        .ref(`users/${queryParams.uid}/cards`)
        .once("value")
        .then(async (snapshot) => {
          //var splitcardIndex = [];
          const cards = snapshot.val();
          // if (queryParams.index.includes("-")) {
          //   splitcardIndex = queryParams.index.split("-");
          // };

          const contact = cards.filter((data) => {
            // if (splitcardIndex.length > 0) {
            //   return data.index === splitcardIndex[0];
            // }
            // else {
            return data.index === queryParams.index;
            //}
          });

          // const res = await axios.get("https://backend.popitapp.nl/image", {
          //   params: {
          //     url: contact[0].image,
          //   },
          // });
          const name1 = contact[0].name.includes(" ")
            ? contact[0].name.split(" ")
            : contact[0].name;
          const first_name = typeof name1 === "string" ? contact[0].name : name1[0];
          const last_name = typeof name1 === "string" ? "" : name1[name1.length - 1];


          vCardData
            .addName(last_name, first_name || "No Name")
            .addCompany(contact[0].company || "No Company")
            .addJobtitle(contact[0].job || "No Job")
            .addRole(contact[0].job || "No Role")
            .addNote(contact[0].bio || "No Bio")
            .addPhoneNumber(contact[0].number, "PREF;WORK")
            //.addPhoto(res.data.img, res.data.mime)

          for (let j = 0; j < contactlinks.length; j++) {
            if (contact[0][contactlinks[j]] !== undefined) {
              for (let i = 0; i < contact[0][contactlinks[j]].length; i++) {
                if (
                  contactlinks[j] === "numbers" ||
                  contactlinks[j] === "whatsapp" ||
                  contactlinks[j] === "call"
                ) {
                  vCardData.addPhoneNumber(
                    contact[0][contactlinks[j]][i].value,
                    "Work"
                  );
                } else if (contactlinks[j] === "emails") {
                  vCardData.addEmail(
                    contact[0][contactlinks[j]][i].value,
                    "Email"
                  );
                } else if (contactlinks[j] === "website") {
                  vCardData.addURL(contact[0][contactlinks[j]][i].value);
                } else if (contactlinks[j] === "addresses") {
                  vCardData.addAddress(contact[0][contactlinks[j]][i].value);
                } else if (contactlinks[j] === "embedded") {
                  vCardData.addSocial(
                    "https://www.youtube.com/watch?v=" +
                      contact[0][contactlinks[j]][i].value,
                    "youtube",
                    contact[0][contactlinks[j]][i].value
                  );
                } else if (contact[0][contactlinks[j]][i].value !== undefined) {
                  if (
                    contact[0][contactlinks[j]][i].value.startsWith(
                      "https://"
                    ) ||
                    contact[0][contactlinks[j]][i].value.startsWith(
                      "http://"
                    ) ||
                    contact[0][contactlinks[j]][i].value.startsWith(
                      "Https://"
                    ) ||
                    contact[0][contactlinks[j]][i].value.startsWith("Http://")
                  ) {
                    const val = contact[0][contactlinks[j]][i].value.split("/");
                    const value = val[val.length - 1];
                    var name = "";
                    if (
                      contact[0][contactlinks[j]][i].value.includes(
                        contactlinks[j]
                      )
                    ) {
                      name = contactlinks[j];
                      vCardData.addSocial(
                        contact[0][contactlinks[j]][i].value,
                        name,
                        value
                      );
                    }
                  } else if (contact[0][contactlinks[j]][i].name === "Twitch") {
                    vCardData.addSocial(
                      "https://twitch.tv/" +
                        contact[0][contactlinks[j]][i].value,
                      "twitch",
                      contact[0][contactlinks[j]][i].value
                    );
                  } else if (contact[0][contactlinks[j]][i].name === "Cash") {
                    vCardData.addSocial(
                      "https://cash.app/$" +
                        contact[0][contactlinks[j]][i].value,
                      "cash",
                      contact[0][contactlinks[j]][i].value
                    );
                  } else if (contact[0][contactlinks[j]][i].name === "Venmo") {
                    vCardData.addSocial(
                      "https://venmo.com/" +
                        contact[0][contactlinks[j]][i].value,
                      "venmo",
                      contact[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0][contactlinks[j]][i].name === "twitter" ||
                    contact[0][contactlinks[j]][i].name === "X" ||
                    contact[0][contactlinks[j]][i].name === "Twitter"
                  ) {
                    vCardData.addSocial(
                      "https://twitter.com/" +
                        contact[0][contactlinks[j]][i].value,
                      "twitter",
                      contact[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0][contactlinks[j]][i].name === "Clubhouse"
                  ) {
                    vCardData.addSocial(
                      "https://clubhouse.com/@" +
                        contact[0][contactlinks[j]][i].value,
                      "clubhouse",
                      contact[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0][contactlinks[j]][i].name === "Telegram"
                  ) {
                    vCardData.addSocial(
                      "https://t.me/" + contact[0][contactlinks[j]][i].value,
                      "telegram",
                      contact[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0][contactlinks[j]][i].name === "Pinterest"
                  ) {
                    vCardData.addSocial(
                      "https://pinterest.com/" +
                        contact[0][contactlinks[j]][i].value,
                      "pinterest",
                      contact[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0][contactlinks[j]][i].name === "Instagram"
                  ) {
                    vCardData.addSocial(
                      `https://www.instagram.com/${
                        contact[0][contactlinks[j]][i].value
                      }`,
                      "instagram",
                      contact[0][contactlinks[j]][i].value
                    );
                  } else if (contact[0][contactlinks[j]][i].name === "Tiktok") {
                    vCardData.addSocial(
                      "https://tiktok.com/@" +
                        contact[0][contactlinks[j]][i].value,
                      "tiktok",
                      contact[0][contactlinks[j]][i].value
                    );
                  } else if (
                    contact[0][contactlinks[j]][i].name === "Snapchat"
                  ) {
                    vCardData.addSocial(
                      "https://snapchat.com/add/" +
                        contact[0][contactlinks[j]][i].value,
                      "snapchat",
                      contact[0][contactlinks[j]][i].value
                    );
                  }
                } else {
                }
              }
            }
          }

          // Direct VCF DOwnloader Code
          const element = document.createElement("a");

          element.setAttribute(
            "href",
            "data:text/vcard;charset=utf-8," +
              encodeURIComponent(vCardData.toString())
          );
          if (contact[0].name) {
            element.setAttribute("download", `${contact[0].name}.vcf`);
          } else {
            element.setAttribute("download", "Contact.vcf");
          }
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        });
        
        
      }
      else if (queryParams.file === "card_csv") {

        firebase
        .database()
        .ref(`users/${queryParams.uid}/cards`)
        .once("value")
        .then(async(snapshot) => {
          const cards = snapshot.val();
          const contact = cards.filter((data) => {
            return data.index === queryParams.index;
          });
        const headers = [
          "Name",
          "Company",
          "Job",
          "Note",
          "Locaton",
        ];
        const csvContent = [headers.join(",")]; // Creating the header row

        contact.forEach((item) => {
          var quotedBio2 = ''
          if (item.bio.includes('\n')){
            const quotedBio = `"${item.bio.replace("\n", ' ')}"`;
            const quotedBio1 = `"${quotedBio.replace(",", ' ')}"`;
            quotedBio2 = `"${quotedBio1.replace("\xa0", '')}"`;
          }
          else if (item.bio.includes(',')){
            const quotedBio1 = `"${item.bio.replace(",", ' ')}"`;
            quotedBio2 = `"${quotedBio1.replace("\xa0", '')}"`;
          }

          var add = ''

          if (item.address.includes('\n')){
            const add2 = `${item.address.replace("\n", ' ')}`;
            const add1 = `${add2.replace(",", ' ')}`;
            add = `${add1.replace("\xa0", '')}`;
          }
          else if (item.address.includes(',')){
            const add1 = `${item.address.replace(",", ' ')}`;
            add = `${add1.replace("\xa0", '')}`;
          }

          
          
          const row = [
            item.name,
            item.company,
            item.job,
            quotedBio2,
            add,
          ];
          csvContent.push(row.join(","));
        });

        const prompt = window.confirm("Do you want to download Csv?");

        if (prompt) {
          // Create a Blob with the CSV data
          const blob = new Blob([csvContent.join("\n")], {
            type: "text/csv",
          });

          // Create a URL for the Blob
          const url = URL.createObjectURL(blob);

          // Create a temporary anchor element to trigger the download
          const a = document.createElement("a");
          a.href = url;
          a.download = "data.csv";
          a.click();

          // Clean up resources
          URL.revokeObjectURL(url);
        }
      })
    }
    }
                       
    else{
      saveContact(queryParams)
    }

    // OLD CODE
    // if (queryParams.file.includes("contact")) {
    //   firebase
    //     .database()
    //     .ref(`users/${queryParams.uid}/contact`)
    //     .once("value")
    //     .then((snapshot) => {
    //       const cards = snapshot.val();
    //       const contact = cards.filter((data) => {
    //         return data.index === queryParams.index;
    //       });

    //       if (queryParams.file === "contact") {
    //         vCardData
    //           .addName(contact[0].name || "No Name")
    //           .addCompany(contact[0].company || "No Company")
    //           .addJobtitle(contact[0].job || "No Job")
    //           .addRole(contact[0].job || "No Job")
    //           .addEmail(contact[0].email || "Null")
    //           .addPhoneNumber(
    //             typeof contact[0].numbers === "string"
    //               ? contact[0].numbers
    //               : contact[0].numbers
    //               ? contact[0].numbers[0]
    //               : "Null",
    //             "PREF;WORK"
    //           )
    //           .addURL(
    //             typeof contact[0].website === "string"
    //               ? contact[0].website
    //               : contact[0].website
    //               ? contact[0].website[0]
    //               : "Null"
    //           );

    //         // Create a Blob with the vCard data

    //         const element = document.createElement("a");

    //         element.setAttribute(
    //           "href",
    //           "data:text/vcard;charset=utf-8," +
    //             encodeURIComponent(vCardData.toString())
    //         );
    //         if (contact[0].name) {
    //             element.setAttribute("download", `${contact[0].name}.vcf`);
    //           } else {
    //             element.setAttribute("download", `Contact.vcf`);
    //           }
    //         element.style.display = "none";
    //         document.body.appendChild(element);
    //         element.click();
    //         document.body.removeChild(element);
    //       } else if (queryParams.file === "contact_csv") {
    //         // Create the CSV content from the dictionary data
    //         const headers = [
    //           "Name",
    //           "Company",
    //           "Job",
    //           "Email",
    //           "Number",
    //           "Website",
    //           "Note",
    //           "Locaton",
    //         ];
    //         const csvContent = [headers.join(",")]; // Creating the header row

    //         contact.forEach((item) => {
    //           const row = [
    //             item.name,
    //             item.company,
    //             item.jobTitle,
    //             item.email,
    //             item.website,
    //             item.note,
    //             item.location,
    //           ];
    //           csvContent.push(row.join(","));
    //         });

    //         const prompt = window.confirm("Do you want to download Csv?");

    //         if (prompt) {
    //           // Create a Blob with the CSV data
    //           const blob = new Blob([csvContent.join("\n")], {
    //             type: "text/csv",
    //           });

    //           // Create a URL for the Blob
    //           const url = URL.createObjectURL(blob);

    //           // Create a temporary anchor element to trigger the download
    //           const a = document.createElement("a");
    //           a.href = url;
    //           a.download = "data.csv";
    //           a.click();

    //           // Clean up resources
    //           URL.revokeObjectURL(url);
    //         }
    //       }
    //     });
    // } else if (queryParams.file.includes("card")) {
     

    //   firebase
    //     .database()
    //     .ref(`users/${queryParams.uid}`)
    //     .once("value")
    //     .then((snapshot) => {
    //       const emaildata = snapshot.val();
          
    //       vCardData.addEmail(emaildata.email || "Email does not exist", "Email");
        
    //     })

    //   firebase
    //     .database()
    //     .ref(`users/${queryParams.uid}/cards`)
    //     .once("value")
    //     .then((snapshot) => {
    //       var splitcardIndex = [];
    //       const cards = snapshot.val();
    //       console.log("cards", cards);
    //       if (queryParams.index.includes("-")) {
    //         splitcardIndex = queryParams.index.split("-"); 
    //       };
          

    //       const contact = cards.filter((data) => {
    //         if (splitcardIndex.length > 0) {
    //           return data.index === splitcardIndex[0];
    //         }
    //         else {
    //         return data.index === queryParams.index;
    //       }
    //       });
    //       // console.log("qP:", queryParams.file);
    //       console.log("ssss: ", contact[0].image);

                  

          
    //       if (queryParams.file === "card") {
    //         console.log("ABCCC: ", contact[0].website);
    //         const imageBase64 = contact[0]['image'].toString("base64");
    //         vCardData
    //           .addName(contact[0].name || "No Name")
    //           .addCompany(contact[0].company || "No Company")
    //           .addJobtitle(contact[0].job || "No Job")
    //           .addRole(contact[0].job || "No Role")
    //           .addNote(contact[0].bio || "No Bio")
    //           .addPhoneNumber(contact[0].number, "PREF;WORK")
    //           .addPhoto(imageBase64, 'JPEG');

    //         for (let j = 0; j < contactlinks.length; j++) {
    //           if (contact[0][contactlinks[j]] !== undefined) {
    //             // console.log(contactlinks[j], ":", contact[0][contactlinks[j]]);
    //             for (let i = 0; i < contact[0][contactlinks[j]].length; i++) {
    //               // console.log("I: ", contact[0][contactlinks[j]][i]);
    //               if (
    //                 contactlinks[j] === "numbers" ||
    //                 contactlinks[j] === "whatsapp" ||
    //                 contactlinks[j] === "call"
    //               ) {
    //                 vCardData.addPhoneNumber(
    //                   contact[0][contactlinks[j]][i].value,
    //                   "Work"
    //                 );
    //               } else if (contactlinks[j] === "emails") {
    //                 vCardData.addEmail(
    //                   contact[0][contactlinks[j]][i].value,
    //                   "Email"
    //                 );
    //               } else if (contactlinks[j] === "addresses") {
    //                 vCardData.addAddress(
    //                   contact[0][contactlinks[j]][i].value
                      
    //                 );
    //               } else if (contact[0][contactlinks[j]][i].value !== undefined) {
    //                 if (
    //                   contact[0][contactlinks[j]][i].value.startsWith(
    //                     "https://"
    //                   ) ||
    //                   contact[0][contactlinks[j]][i].value.startsWith(
    //                     "http://"
    //                   ) ||
    //                   contact[0][contactlinks[j]][i].value.startsWith(
    //                     "Https://"
    //                   ) ||
    //                   contact[0][contactlinks[j]][i].value.startsWith("Http://")
    //                 ) {
    //                   vCardData.addURL(contact[0][contactlinks[j]][i].value);
    //                 } else if (
    //                   contact[0][contactlinks[j]][i].name === "Twitch"
    //                 ) {
    //                   vCardData.addURL(
    //                     "https://twitch.tv/" +
    //                       contact[0][contactlinks[j]][i].value
    //                   );
    //                 } else if (contact[0][contactlinks[j]][i].name === "Cash") {
    //                   vCardData.addURL(
    //                     "https://cash.app/$" +
    //                       contact[0][contactlinks[j]][i].value
    //                   );
    //                 } else if (
    //                   contact[0][contactlinks[j]][i].name === "Venmo"
    //                 ) {
    //                   vCardData.addURL(
    //                     "https://venmo.com/" +
    //                       contact[0][contactlinks[j]][i].value
    //                   );
    //                 } else if (
    //                   contact[0][contactlinks[j]][i].name ===
    //                     "twitter" ||
    //                   contact[0][contactlinks[j]][i].name ===
    //                     "X" ||
    //                   contact[0][contactlinks[j]][i].name ===
    //                     "Twitter"
    //                 ) {
    //                   vCardData.addURL(
    //                     "https://twitter.com/" +
    //                       contact[0][contactlinks[j]][i].value
    //                   );
    //                 } else if (
    //                   contact[0][contactlinks[j]][i].name === "Clubhouse"
    //                 ) {
    //                   vCardData.addURL(
    //                     "https://clubhouse.com/@" +
    //                       contact[0][contactlinks[j]][i].value
    //                   );
    //                 } else if (
    //                   contact[0][contactlinks[j]][i].name === "Telegram"
    //                 ) {
    //                   vCardData.addURL(
    //                     "https://t.me/" + contact[0][contactlinks[j]][i].value
    //                   );
    //                 } else if (
    //                   contact[0][contactlinks[j]][i].name === "Pinterest"
    //                 ) {
    //                   vCardData.addURL(
    //                     "https://pinterest.com/" +
    //                       contact[0][contactlinks[j]][i].value
    //                   );
    //                 } else if (
    //                   contact[0][contactlinks[j]][i].name === "Instagram"
    //                 ) {
    //                   vCardData.addURL(
    //                     `https://www.instagram.com/${
    //                       contact[0][contactlinks[j]][i].value
    //                     }`
    //                   );
    //                 } else if (
    //                   contact[0][contactlinks[j]][i].name === "Tiktok"
    //                 ) {
    //                   vCardData.addURL(
    //                     "https://tiktok.com/@" +
    //                       contact[0][contactlinks[j]][i].value
    //                   );
    //                 } else if (
    //                   contact[0][contactlinks[j]][i].name === "Snapchat"
    //                 ) {
    //                   vCardData.addURL(
    //                     "https://snapchat.com/add/" +
    //                       contact[0][contactlinks[j]][i].value
    //                   );
    //                 }
    //               } else {

    //               }
    //             }
    //           }
    //         }

    //         // Direct VCF DOwnloader Code
    //         const element = document.createElement("a");

    //         element.setAttribute(
    //           "href",
    //           "data:text/vcard;charset=utf-8," +
    //             encodeURIComponent(vCardData.toString())
    //         );
    //         if (contact[0].name) {
    //             element.setAttribute("download", `${contact[0].name}.vcf`);
    //           } else {
    //             element.setAttribute("download", `Contact.vcf`);
    //           }
    //         element.style.display = "none";
    //         document.body.appendChild(element);
    //         element.click();
    //         document.body.removeChild(element);

            

            

            
    //         // Create a Blob with the vCard data

    //         // const blob = new Blob([vCardData], { type: "text/vcard" });
    //         // const url = window.URL.createObjectURL(new Blob([blob]));

    //         // const prompt = window.confirm("Do you want to download vCard?");

    //         // if (prompt) {
    //         //   // Create an anchor element to trigger the download

    //         //   const link = document.createElement("a");
    //         //   link.href = url;
    //         //   if (contact[0].name) {
    //         //     link.setAttribute("download", `${contact[0].name}.vcf`);
    //         //   } else {
    //         //     link.setAttribute("download", `Contact.vcf`);
    //         //   }

    //         //   // Append the anchor element to the DOM and click it

    //         //   document.body.appendChild(link);
    //         //   link.click();

    //         //   // Clean up and remove the anchor element

    //         //   link.parentNode.removeChild(link);
    //         // }




    //       } else if (queryParams.file === "card_csv") {
    //         // Create the CSV content from the dictionary data
    //         const headers = [
    //           "Name",
    //           "Company",
    //           "Job",
    //           "Email",
    //           "Number",
    //           "Website",
    //           "Note",
    //           "Locaton",
    //         ];
    //         const csvContent = [headers.join(",")]; // Creating the header row

    //         contact.forEach((item) => {
    //           const row = [
    //             item.name,
    //             item.company,
    //             item.jobTitle,
    //             item.email,
    //             item.website,
    //             item.note,
    //             item.location,
    //           ];
    //           csvContent.push(row.join(","));
    //         });

    //         const prompt = window.confirm("Do you want to download Csv?");

    //         if (prompt) {
    //           // Create a Blob with the CSV data
    //           const blob = new Blob([csvContent.join("\n")], {
    //             type: "text/csv",
    //           });

    //           // Create a URL for the Blob
    //           const url = URL.createObjectURL(blob);

    //           // Create a temporary anchor element to trigger the download
    //           const a = document.createElement("a");
    //           a.href = url;
    //           a.download = "data.csv";
    //           a.click();

    //           // Clean up resources
    //           URL.revokeObjectURL(url);
    //         }
    //       }
    //     });
    // } else if (queryParams.file.includes("ContactCard")) {
      

    //   firebase
    //     .database()
    //     .ref(`users/${queryParams.uid}/cards`)
    //     .once("value")
    //     .then((snapshot) => {
    //       const cards = snapshot.val();
          
    //       const contact = cards.filter((data) => {
    //         return data.index === queryParams.index;
    //       });

       

    //       if (queryParams.file === "ContactCard") {
    //         vCardData
    //           .addName(contact[0].name || "No Name")
    //           .addCompany(contact[0].company || "No Company")
    //           .addJobtitle(contact[0].job || "No Job")
    //           .addRole(contact[0].job || "No Role")
    //           .addNote(contact[0].bio || "No Bio")
    //           .addPhoneNumber(contact[0].number, "PREF;WORK");

    //         for (let j = 0; j < contactlinks.length; j++) {
    //           if (contact[0].ContactCard[0][contactlinks[j]] !== undefined) {
                
                
    //             for (
    //               let i = 0;
    //               i < contact[0].ContactCard[0][contactlinks[j]].length;
    //               i++
    //             ) {
                  
    //             if (contact[0].ContactCard[0][contactlinks[j]][i].check === true) {
                  
                
    //               if (
    //                 contactlinks[j] === "numbers" ||
    //                 contactlinks[j] === "whatsapp" ||
    //                 contactlinks[j] === "call"
    //               ) {
    //                 vCardData.addPhoneNumber(
    //                   contact[0].ContactCard[0][contactlinks[j]][i].value,
    //                   "Work"
    //                 );
    //               } else if (contactlinks[j] === "emails") {
    //                 vCardData.addEmail(
    //                   contact[0].ContactCard[0][contactlinks[j]][i].value,
    //                   "Email"
    //                 );
    //               } else if (contactlinks[j] === "addresses") {
    //                 vCardData.addAddress(
    //                   contact[0].ContactCard[0][contactlinks[j]][i].value
    //                 );
    //               } else if (
    //                 contact[0].ContactCard[0][contactlinks[j]][i].value !==
    //                 undefined
    //               ) {
    //                 if (
    //                   contact[0].ContactCard[0][contactlinks[j]][
    //                     i
    //                   ].value.startsWith("https://") ||
    //                   contact[0].ContactCard[0][contactlinks[j]][
    //                     i
    //                   ].value.startsWith("http://") ||
    //                   contact[0].ContactCard[0][contactlinks[j]][
    //                     i
    //                   ].value.startsWith("Https://") ||
    //                   contact[0].ContactCard[0][contactlinks[j]][
    //                     i
    //                   ].value.startsWith("Http://")
    //                 ) {
                      

    //                   vCardData.addURL(
    //                     contact[0].ContactCard[0][contactlinks[j]][i].value
    //                   );
    //                 } else if (
    //                   contact[0].ContactCard[0][contactlinks[j]][i].name.charAt(0).toUpperCase() +
    // contact[0].ContactCard[0][contactlinks[j]][i].name.slice(1).toLowerCase() ===
    //                   "Twitch"
    //                 ) {
    //                   vCardData.addURL(
    //                     "https://twitch.tv/" +
    //                       contact[0].ContactCard[0][contactlinks[j]][i].value
    //                   );
    //                 } else if (
    //                   contact[0].ContactCard[0][contactlinks[j]][i].name.charAt(0).toUpperCase() +
    // contact[0].ContactCard[0][contactlinks[j]][i].name.slice(1).toLowerCase() ===
    //                   "Cash"
    //                 ) {
    //                   vCardData.addURL(
    //                     "https://cash.app/$" +
    //                       contact[0].ContactCard[0][contactlinks[j]][i].value
    //                   );
    //                 } else if (
    //                   contact[0].ContactCard[0][contactlinks[j]][i].name.charAt(0).toUpperCase() +
    // contact[0].ContactCard[0][contactlinks[j]][i].name.slice(1).toLowerCase() ===
    //                   "Venmo"
    //                 ) {
    //                   vCardData.addURL(
    //                     "https://venmo.com/" +
    //                       contact[0].ContactCard[0][contactlinks[j]][i].value
    //                   );
    //                 } else if (
    //                   contact[0].ContactCard[0][contactlinks[j]][i].name.charAt(0).toUpperCase() +
    // contact[0].ContactCard[0][contactlinks[j]][i].name.slice(1).toLowerCase() ===
    //                     "twitter" ||
    //                   contact[0].ContactCard[0][contactlinks[j]][i].name.charAt(0).toUpperCase() +
    // contact[0].ContactCard[0][contactlinks[j]][i].name.slice(1).toLowerCase() ===
    //                     "X" ||
    //                   contact[0].ContactCard[0][contactlinks[j]][i].name.charAt(0).toUpperCase() +
    // contact[0].ContactCard[0][contactlinks[j]][i].name.slice(1).toLowerCase() === "Twitter"
    //                 ) {
    //                   vCardData.addURL(
    //                     "https://twitter.com/" +
    //                       contact[0].ContactCard[0][contactlinks[j]][i].value
    //                   );
    //                 } else if (
    //                   contact[0].ContactCard[0][contactlinks[j]][i].name.charAt(0).toUpperCase() +
    // contact[0].ContactCard[0][contactlinks[j]][i].name.slice(1).toLowerCase() ===
    //                   "Clubhouse"
    //                 ) {
    //                   vCardData.addURL(
    //                     "https://clubhouse.com/@" +
    //                       contact[0].ContactCard[0][contactlinks[j]][i].value
    //                   );
    //                 } else if (
    //                   contact[0].ContactCard[0][contactlinks[j]][i].name.charAt(0).toUpperCase() +
    // contact[0].ContactCard[0][contactlinks[j]][i].name.slice(1).toLowerCase() ===
    //                   "Telegram"
    //                 ) {
    //                   vCardData.addURL(
    //                     "https://t.me/" +
    //                       contact[0].ContactCard[0][contactlinks[j]][i].value
    //                   );
    //                 } else if (
    //                   contact[0].ContactCard[0][contactlinks[j]][i].name.charAt(0).toUpperCase() +
    // contact[0].ContactCard[0][contactlinks[j]][i].name.slice(1).toLowerCase() ===
    //                   "Pinterest"
    //                 ) {
    //                   vCardData.addURL(
    //                     "https://pinterest.com/" +
    //                       contact[0].ContactCard[0][contactlinks[j]][i].value
    //                   );
    //                 } else if (
    //                   contact[0].ContactCard[0][contactlinks[j]][i].name.charAt(0).toUpperCase() +
    // contact[0].ContactCard[0][contactlinks[j]][i].name.slice(1).toLowerCase() ===
    //                   "Instagram"
    //                 ) {
    //                   vCardData.addURL(
    //                     `https://www.instagram.com/${
    //                       contact[0].ContactCard[0][contactlinks[j]][i].value
    //                     }`
    //                   );
    //                 } else if (
    //                   contact[0].ContactCard[0][contactlinks[j]][i].name.charAt(0).toUpperCase() +
    // contact[0].ContactCard[0][contactlinks[j]][i].name.slice(1).toLowerCase() ===
    //                   "Tiktok"
    //                 ) {
    //                   vCardData.addURL(
    //                     "https://tiktok.com/@" +
    //                       contact[0].ContactCard[0][contactlinks[j]][i].value
    //                   );
    //                 } else if (
    //                   contact[0].ContactCard[0][contactlinks[j]][i].name.charAt(0).toUpperCase() +
    // contact[0].ContactCard[0][contactlinks[j]][i].name.slice(1).toLowerCase() ===
    //                   "Snapchat"
    //                 ) {
    //                   vCardData.addURL(
    //                     "https://snapchat.com/add/" +
    //                       contact[0].ContactCard[0][contactlinks[j]][i].value
    //                   );
    //                 }
    //               } 
    //             }
    //             }}
    //         }
    //         // Create a Blob with the vCard data

    //         const blob = new Blob([vCardData], { type: "text/vcard" });
    //         const url = window.URL.createObjectURL(new Blob([blob]));

    //         const prompt = window.confirm("Do you want to download vCard?");

    //         if (prompt) {
    //           // Create an anchor element to trigger the download

    //           const link = document.createElement("a");
    //           link.href = url;
    //           if (contact[0].name) {
    //             link.setAttribute("download", `${contact[0].name}.vcf`);
    //           } else {
    //             link.setAttribute("download", `Contact.vcf`);
    //           }

    //           // Append the anchor element to the DOM and click it

    //           document.body.appendChild(link);
    //           link.click();

    //           // Clean up and remove the anchor element

    //           link.parentNode.removeChild(link);
    //         }
    //       } else if (queryParams.file === "contactCard.csv") {
    //         // Create the CSV content from the dictionary data
    //         const headers = [
    //           "Name",
    //           "Company",
    //           "Job",
    //           "Email",
    //           "Number",
    //           "Website",
    //           "Note",
    //           "Locaton",
    //         ];
    //         const csvContent = [headers.join(",")]; // Creating the header row

    //         contact.forEach((item) => {
    //           const row = [
    //             item.name,
    //             item.company,
    //             item.jobTitle,
    //             item.email,
    //             item.website,
    //             item.note,
    //             item.location,
    //           ];
    //           csvContent.push(row.join(","));
    //         });

    //         const prompt = window.confirm("Do you want to download Csv?");

    //         if (prompt) {
    //           // Create a Blob with the CSV data
    //           const blob = new Blob([csvContent.join("\n")], {
    //             type: "text/csv",
    //           });

    //           // Create a URL for the Blob
    //           const url = URL.createObjectURL(blob);

    //           // Create a temporary anchor element to trigger the download
    //           const a = document.createElement("a");
    //           a.href = url;
    //           a.download = "data.csv";
    //           a.click();

    //           // Clean up resources
    //           URL.revokeObjectURL(url);
    //         }
    //       }
    //     });
    // } else if (queryParams.file.includes("connectCard")) {
    //   firebase
    //     .database()
    //     .ref(`users/${queryParams.uid}/connection`)
    //     .once("value")
    //     .then((snapshot) => {
    //       const cards = snapshot.val();
    //       const contact = cards.filter((data) => {
    //         return data.index === queryParams.index;
    //       });

    //       console.log(contact[0]);

    //       if (queryParams.file === "connectCard") {
    //         vCardData
    //           .addName(contact[0].name || "No Name")
    //           .addCompany(contact[0].company || "No Company")
    //           .addJobtitle(contact[0].job || "No Job")
    //           .addRole(contact[0].job || "No Job")
    //           .addEmail(contact[0].email || "Null")
    //           .addPhoneNumber(
    //             typeof contact[0].numbers === "string"
    //               ? contact[0].numbers
    //               : contact[0].numbers
    //               ? contact[0].numbers[0]
    //               : "Null",
    //             "PREF;WORK"
    //           )
    //           .addURL(
    //             typeof contact[0].website === "string"
    //               ? contact[0].website
    //               : contact[0].website
    //               ? contact[0].website[0]
    //               : "Null"
    //           );

    //         // Create a Blob with the vCard data

    //         const blob = new Blob([vCardData], { type: "text/vcard" });
    //         const url = window.URL.createObjectURL(new Blob([blob]));

    //         const prompt = window.confirm("Do you want to download vCard?");

    //         if (prompt) {
    //           // Create an anchor element to trigger the download

    //           const link = document.createElement("a");
    //           link.href = url;
    //           if (contact[0].name) {
    //             link.setAttribute("download", `${contact[0].name}.vcf`);
    //           } else {
    //             link.setAttribute("download", `Contact.vcf`);
    //           }

    //           // Append the anchor element to the DOM and click it

    //           document.body.appendChild(link);
    //           link.click();

    //           // Clean up and remove the anchor element

    //           link.parentNode.removeChild(link);
    //         }
    //       } else if (queryParams.file === "connectCard_csv") {
    //         // Create the CSV content from the dictionary data
    //         const headers = [
    //           "Name",
    //           "Company",
    //           "Job",
    //           "Email",
    //           "Number",
    //           "Website",
    //           "Note",
    //           "Locaton",
    //         ];
    //         const csvContent = [headers.join(",")]; // Creating the header row

    //         contact.forEach((item) => {
    //           const row = [
    //             item.name,
    //             item.company,
    //             item.job,
    //             item.email,
    //             item.website,
    //             item.note,
    //             item.location,
    //           ];
    //           csvContent.push(row.join(","));
    //         });

    //         const prompt = window.confirm("Do you want to download Csv?");

    //         if (prompt) {
    //           // Create a Blob with the CSV data
    //           const blob = new Blob([csvContent.join("\n")], {
    //             type: "text/csv",
    //           });

    //           // Create a URL for the Blob
    //           const url = URL.createObjectURL(blob);

    //           // Create a temporary anchor element to trigger the download
    //           const a = document.createElement("a");
    //           a.href = url;
    //           a.download = "data.csv";
    //           a.click();

    //           // Clean up resources
    //           URL.revokeObjectURL(url);
    //         }
    //       }
    //     });
    // }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      {/* <div>
        <img
          src={require("../images/pop_it_Black_wb.png")}
          style={{ width: 150, height: 80 }}
        ></img>
      </div>
      <p style={{ fontFamily: "Roboto" }}>
        Please wait while the file is downloading
      </p>
      <ReactLoading type="bubbles" color="black" height={100} width={50} /> */}
    </div>
  );
}

export default Test;
