import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Membership = ({ setQna, dates, setDates,membershipType, setMembershipType }) => {
  const [showNext, setShowNext] = useState(false);

  const handleMembershipChange = (type) => {
    setMembershipType(type);
    setShowNext(false);
    setDates({ start: null, end: null });
  };

  const handleNext = () => {
    if (membershipType === "normal") {
      setQna(false);
    } else if (membershipType) {
      setShowNext(true);
    } else {
      alert("Please select a membership type");
    }
  };

  const handleBack = () => {
    setShowNext(false);
    setDates({ start: null, end: null });
  };

  const handleSubmit = () => {
    if (membershipType === "normal") {
      console.log("Normal package selected.");
    } else if (membershipType === "pro") {
      if (dates.start && dates.end) {
        setQna(false);
      } else {
        alert("Please select a date range for Pro package.");
      }
    }
  };

  return (
    <div className="space-y-5">
      {!showNext && (
        <div>
          <h1 className="font-medium  text-xl mb-5">
            Please Select Type of Membership
          </h1>
          <div className="flex gap-10">
            <label className="flex gap-1">
              <input
                type="checkbox"
                checked={membershipType === "normal"}
                className="mt-1 accent-slate-950"
                onChange={() => handleMembershipChange("normal")}
              />
              <span>Normal</span>
            </label>
            <label className="flex gap-1">
              <input
                type="checkbox"
                checked={membershipType === "pro"}
                className="mt-1 accent-slate-950"
                onChange={() => handleMembershipChange("pro")}
              />
              <span>Pro</span>
            </label>
          </div>
          <button
            onClick={handleNext}
            className="bg-blue-500 p-2 mt-5 text-white rounded-lg px-5"
          >
            Next
          </button>
        </div>
      )}

      {showNext && membershipType === "pro" && (
        <div>
          <h1 className="font-medium py-5 text-xl mb-5">
            Please Select Duration of Membership
          </h1>
          <div className="flex items-center gap-5">
            <label className="inline-flex items-center gap-2 ">
              <span>Start Date:</span>
              <DatePicker
                selected={dates.start}
                onChange={(date) =>
                  setDates((prev) => ({ ...prev, start: date }))
                }
                dateFormat="yyyy/MM/dd"
                className="rounded-md outline-none border-none p-1"
              />
            </label>
            <label className="inline-flex items-center gap-2 ">
              <span>End Date:</span>
              <DatePicker
                selected={dates.end}
                onChange={(date) =>
                  setDates((prev) => ({ ...prev, end: date }))
                }
                dateFormat="yyyy/MM/dd"
                className="rounded-md outline-none border-none p-1"
              />
            </label>
          </div>
        </div>
      )}

      {showNext && membershipType === "normal" && (
        <div>
          <h1 className="font-medium py-5 text-xl mb-5">
            Normal membership selected. No further options.
          </h1>
        </div>
      )}

      {showNext &&
        (membershipType === "pro" || membershipType === "normal") && (
          <div className="space-x-2">
            {membershipType === "pro" && (
              <button
                onClick={handleBack}
                className="bg-gray-800 p-2 mt-5 text-white rounded-lg px-5 mr-2"
              >
                Back
              </button>
            )}
            <button
              onClick={handleSubmit}
              className="bg-blue-500 p-2 mt-5 text-white rounded-lg px-5"
            >
              Next
            </button>
          </div>
        )}
    </div>
  );
};

export default Membership;
