import React from "react";
import { useParams } from "react-router-dom";
import "firebase/database";
import firebase from "firebase";
import "../components/Firebase";

export default function Integration () {

    const queryParams = useParams();

    const headers = [
        "Name",
        "Company",
        "Job",
        "Note",
        "Location",
    ];

    let csvContent = [headers.join(",")];

    const integration = async () => {
        // Fetch data_contact and data
        const snapshot_contact = await firebase.database().ref(`users/${queryParams.uid}`).once("value");
        const data_contact = snapshot_contact.val();

        const snapshot = await firebase.database().ref(`users/${queryParams.uid}/connection`).once("value");
        const data = snapshot.val();

        // Handle data from 'connection'
        if (data) {
            const promises = data.map(async (item) => {
                const cardSnapshot = await firebase.database().ref(`users/${item.uid}/cards`).once("value");
                const cards = cardSnapshot.val();
                const contact = cards.filter((dat) => dat.index === item.index);
                var quotedBio2 = ''
                if(typeof contact[0].bio!== "undefined"){
                    if (contact[0].bio.includes('\n')){
                        const quotedBio = `"${contact[0].bio.replace("\n", ' ')}"`;
                        const quotedBio1 = `"${quotedBio.replace(",", ' ')}"`;
                        quotedBio2 = `"${quotedBio1.replace("\xa0", '')}"`;
                    }
                    else if (contact[0].bio.includes(',')){
                        const quotedBio1 = `"${contact[0].bio.replace(",", ' ')}"`;
                        quotedBio2 = `"${quotedBio1.replace("\xa0", '')}"`;
                    }
                    else{
                        quotedBio2 = contact[0].bio
                    }
                }


                var add = ''
                if (contact[0].address.includes('\n')){
                    const add2 = `${contact[0].address.replace("\n", ' ')}`;
                    const add1 = `${add2.replace(",", ' ')}`;
                    add = `${add1.replace("\xa0", '')}`;
                }
                else if (contact[0].address.includes(',')){
                    const add1 = `${contact[0].address.replace(",", ' ')}`;
                    add = `${add1.replace("\xa0", '')}`;
                }
                else{
                    add = contact[0].address
                }

                if (contact.length > 0) {
                    const row = [
                        contact[0].name,
                        contact[0].company,
                        contact[0].job,
                        quotedBio2,
                        add,
                    ];
                    return row.join(",");
                } else {
                    return null;
                }
            });

            const rows = await Promise.all(promises);
            csvContent = csvContent.concat(rows.filter(row => row !== null));
        }

        // Handle data_contact
        if (data_contact && data_contact.contact) {
            const promises = data_contact.contact.map((itm) => {

                var quotedBio = ''
                if(itm.note){
                    if (itm.note.includes('\n')){
                        const quotedBio = `"${itm.note.replace("\n", ' ')}"`;
                        const quotedBio1 = `"${quotedBio.replace(",", ' ')}"`;
                        quotedBio = `"${quotedBio1.replace("\xa0", '')}"`;
                    }
                    else if (itm.note.includes(',')){
                        const quotedBio1 = `"${itm.note.replace(",", ' ')}"`;
                        quotedBio = `"${quotedBio1.replace("\xa0", '')}"`;
                    }
                    else{
                        quotedBio = itm.note
                    }
                }
               

                var add = ''
                if(itm.location){
                    if (itm.location.includes('\n')){
                        const add2 = `${itm.location.replace("\n", ' ')}`;
                        const add1 = `${add2.replace(",", ' ')}`;
                        add = `${add1.replace("\xa0", '')}`;
                    }
                    else if (itm.location.includes(',')){
                        const add1 = `${itm.location.replace(",", ' ')}`;
                        add = `${add1.replace("\xa0", '')}`;
                    }
                    else{
                        add = itm.location
                    }
                }
                
                const row = [
                    itm.name,
                    itm.company,
                    itm.jobTitle,
                    quotedBio,
                    add,
                ];
                return row.join(",");
            });

            const rows = await Promise.all(promises);
            csvContent = csvContent.concat(rows.filter(row => row !== null));
        }

        // Prompt to download CSV
        const prompt = window.confirm("Do you want to download Csv?");
        if (prompt) {
            const blob = new Blob([csvContent.join("\n")], { type: "text/csv" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "data.csv";
            a.click();
            URL.revokeObjectURL(url);
        }
    };

    React.useEffect(() => {
        integration();
    }, []);

    return(
        <div>
            {/* Render something here if needed */}
        </div>
    );
}
