import React from "react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

const TableComp = ({ data, seteditManager, setFormData, seteditindex }) => {
  if (data.length === 0) {
    return <div></div>;
  }

  const headers = Object.keys(data[0]);

  return (
    <div className=" overflow-auto whitespace-nowrap  h-full min-w-full  w-[200px] max-h-[50vh]">
      {" "}
      <Table className="min-w-full">
        <TableHeader>
          <TableRow>
            {headers.map((header) => (
              <TableHead key={header}>{header}</TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((member, index) => (
            <TableRow
              className="cursor-pointer"
              key={index}
              onClick={() => {
                seteditManager(true);
                setFormData(member);
                seteditindex(index);
              }}
            >
              {headers.map((header) => (
                <TableCell key={header} className="font-medium">
                  {member[header]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TableComp;
